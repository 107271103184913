import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import translations from './language/translations';
import {
  setLocale,
  loadTranslations,
  syncTranslationWithStore,
} from 'react-redux-i18n';

const initialState = {};

const middleware = [thunk];

const fetchGeoLoc = async () => {
  try {
    const url =
      'https://geolocation-db.com/json/7733a990-ebd4-11ea-b9a6-2955706ddbf3';

    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    const data = await res.json();
    if (data.country_code === 'HU') {
      store.dispatch(setLocale('hu'));
    } else {
      store.dispatch(setLocale('en'));
    }
    console.log(data);
  } catch (error) {
    store.dispatch(setLocale('hu'));
  }
};
fetchGeoLoc();
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));

export default store;
