import React, { Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import SmallSlider from '../layout/SmallSlider';

// const SentenceWithLink = <a href=''></a>;
const Duty = (props) => {
  return (
    <Fragment>
      {props.locale === undefined ? (
        <div className='container'>
          <div class='progress'>
            <div class='indeterminate'></div>
          </div>
        </div>
      ) : (
        <Fragment>
          <SmallSlider img='customs' placement='center' slide='slide8' />
          <div className='container'>
            <div className='description'>
              <p className='introductionTitle' style={{ marginTop: '30px' }}>
                <Translate value='duty.title' />
              </p>
              <div>
                <p className='dutyTitle'>
                  <Translate value='duty.descriptionTitle.title1' />
                </p>
                <Translate value='duty.description.part0' />
                <a href='https://vamtanacsado.hu' target='_blank'>
                  www.vamtanacsado.hu
                </a>
                <Translate value='duty.description.part1' />
                <p className='dutyTitle'>
                  <Translate value='duty.descriptionTitle.title2' />
                </p>
                <Translate value='duty.description.part2' />
                <br />
                <p className='dutyTitle'>
                  <Translate value='duty.descriptionTitle.title3' />
                </p>
                <Translate value='duty.description.part3' />
                <a
                  href='https://vamtanacsado.hu/wp-content/uploads/Eseti-kozvetlen-megbizas-20200421.pdf'
                  target='_blank'>
                  https://vamtanacsado.hu/wp-content/uploads/Eseti-kozvetlen-megbizas-20200421.pdf
                </a>
                <br />
                <Translate value='duty.description.part4' />
                <br />
                <Translate value='duty.description.part5' />
                <br />

                <p className='dutyTitle'>
                  <Translate value='duty.descriptionTitle.title4' />
                </p>
                <Translate value='duty.description.part6' />
                <br />
                <Translate value='duty.description.part7' />
                <a href='https://vamtanacsado.hu/ajanlatkeres/' target='_blank'>
                  https://vamtanacsado.hu/ajanlatkeres/
                </a>
                <br />
                <p className='dutyTitle'>
                  <Translate value='duty.descriptionTitle.title5' />
                </p>
                <Translate value='duty.description.part8' />
                <br />
                <Translate value='duty.description.part9' />
                <a href='https://vamtanacsado.hu/wp-content/uploads/Eseti-kozvetlen-megbizas-20200421.pdf'>
                  https://vamtanacsado.hu/wp-content/uploads/Eseti-kozvetlen-megbizas-20200421.pdf
                </a>
                <br />
                <Translate value='duty.description.part10' />
                <br />
                <Translate value='duty.description.part11' />
                <br />
                <Translate value='duty.description.part12' />
                <br />
                <Translate value='duty.description.part13' />
                <a href='https://vamtanacsado.hu/ajanlatkeres/' target='_blank'>
                  https://vamtanacsado.hu/ajanlatkeres/
                </a>
                <br />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
});
export default connect(mapStateToProps)(Duty);
