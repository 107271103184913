import React from 'react';
import { Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import LanguageSwitcher from './LanguageSwitcher';
import MobileNavBar from './MobileNavBar';
import { connect } from 'react-redux';

const NavBar = (props) => {
  return (
    <div className='headerCont'>
      <div className='contactHeader'>
        <div className='container basicFlex spaceBetween mobileCont'>
          <div className='contactTypes'>
            <div className='contactItem'>
              <img src='/img/number.svg' alt='phone' className='contactIcon' />
              <p>+36303655612</p>
            </div>
            <div className='contactItem'>
              <img src='/img/email.svg' alt='email' className='contactIcon' />
              <p>info@ekytrans.hu</p>
            </div>
            <div className='contactItem'>
              <img src='/img/face.svg' alt='facebook' className='contactIcon' />
              <p>
                <a
                  style={{ color: 'white' }}
                  href='https://www.facebook.com/ekytrans'>
                  /ekytrans
                </a>
              </p>
            </div>
            <div className='contactItem' style={{ display: 'none' }}>
              <img
                src='/img/insta.svg'
                alt='instagram'
                className='contactIcon'
              />
              <p>loremipsum</p>
            </div>
          </div>
          <div className='languageOptions'>
            <p>Hívjon minket! +36303655612</p>
            <LanguageSwitcher />
          </div>
        </div>
      </div>

      <MobileNavBar />
      <ul id='dropdown1' className='dropdown-content'>
        <li>
          <Link to='/jarataink' className='dd-text'>
            <Translate value='navbar.dropdownMenu1.item1' />
          </Link>
        </li>
        <li>
          <Link to='/jarataink' className='dd-text'>
            <Translate value='navbar.dropdownMenu1.item2' />
          </Link>
        </li>

        <li>
          <Link to='/jarataink' className='dd-text'>
            <Translate value='navbar.dropdownMenu1.item3' />
          </Link>
        </li>
        <li>
          <Link to='/jarataink' className='dd-text'>
            <Translate value='navbar.dropdownMenu1.item4' />
          </Link>
        </li>
      </ul>

      {/* <ul
        id='dropdown2'
        className='dropdown-content'>
        <li>
          <Link to='/karrier' className='dd-text'>
            <Translate value='navbar.dropdownMenu2.item1' />
          </Link>
        </li>
        <li>
          <Link to='/karrier' className='dd-text'>
            <Translate value='navbar.dropdownMenu2.item2' />
          </Link>
        </li>

        <li>
          <Link to='/karrier' className='dd-text'>
            <Translate value='navbar.dropdownMenu2.item3' />
          </Link>
        </li>
      </ul> */}
      <div className=' navBarCont'>
        <div className='container basicFlex spaceBetween '>
          {/* eslint-disable-next-line */}
          <a href='#' data-target='mobile-demo' className='sidenav-trigger'>
            <i className='small material-icons' style={{ color: '#505050' }}>
              menu
            </i>
          </a>
          <Link to='/'>
            <div className='logoContainer'>
              <img src='/img/logo.svg' alt='logo' className='logo' />
            </div>
          </Link>
          <div className='navItems'>
            <Link
              to='/'
              className='menuItem basicFlex'
              style={{ textDecoration: 'none', color: '#505050' }}>
              <Translate value='navbar.navItem2'></Translate>
            </Link>
            <Link
              to='/jarataink'
              className='menuItem dropdown-trigger basicFlex'
              data-target='dropdown1'
              style={{ textDecoration: 'none', color: '#505050' }}>
              <Translate value='navbar.navItem3'></Translate>
              <i className='material-icons right'>arrow_drop_down</i>
            </Link>

            <Link
              to='/gepkocsipark'
              className='menuItem basicFlex'
              style={{ textDecoration: 'none', color: '#505050' }}>
              <Translate value='navbar.navItem4'></Translate>
            </Link>
            <Link
              to='/raktarozas'
              className='menuItem  basicFlex'
              style={{ textDecoration: 'none', color: '#505050' }}>
              <Translate value='navbar.navItem5'></Translate>
            </Link>
            <Link
              to='/vamolas'
              className='menuItem  basicFlex'
              style={{ textDecoration: 'none', color: '#505050' }}>
              <Translate value='navbar.navItem1'></Translate>
            </Link>
            <Link
              to='/galeria'
              className='menuItem basicFlex'
              style={{ textDecoration: 'none', color: '#505050' }}>
              <Translate value='navbar.navItem6'></Translate>
            </Link>
            <Link
              to='/karrier'
              // className='menuItem dropdown-trigger basicFlex'
              className='menuItem  basicFlex'
              // data-target='dropdown2'
              style={{
                display: props.locale === 'en' ? 'none' : '',
                textDecoration: 'none',
                color: '#505050',
              }}>
              <Translate value='navbar.navItem8'></Translate>
              {/* <i className='material-icons right'>arrow_drop_down</i> */}
            </Link>
            <Link
              to='/kapcsolat'
              className='menuItem  basicFlex'
              style={{ textDecoration: 'none', color: '#505050' }}>
              <Translate value='navbar.navItem9'></Translate>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
});
export default connect(mapStateToProps)(NavBar);
