import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import LanguageSwitcher from './LanguageSwitcher';
const MobileNavBar = (props) => {
  return (
    <ul className='sidenav' id='mobile-demo'>
      <div className='mobileNavBar'>
        <div className='languageOptions'>
          <LanguageSwitcher />
        </div>
        <div className='contactItem'>
          <img
            src='/img/menuIcons/phone.svg'
            alt='phone'
            className='contactIcon'
          />
          <p>+36303655612</p>
        </div>
        <div className='contactItem'>
          <img
            src='/img/menuIcons/email.svg'
            alt='email'
            className='contactIcon'
          />
          <p>email@email.co</p>
        </div>
        <div className='contactItem'>
          <img
            src='/img/menuIcons/face.svg'
            alt='facebook'
            className='contactIcon'
          />
          <p>/loremipsum</p>
        </div>
        <div className='contactItem' style={{ display: 'none' }}>
          <img
            src='/img/menuIcons/insta.svg'
            alt='instagram'
            className='contactIcon'
          />
          <p>loremipsum</p>
        </div>

        <Link
          to='/vamolas'
          className='menuItem  basicFlex'
          style={{ textDecoration: 'none', color: '#505050' }}>
          <Translate value='navbar.navItem1'></Translate>
        </Link>
        <Link
          to='/'
          className='menuItem basicFlex'
          style={{ textDecoration: 'none', color: '#505050' }}>
          <Translate value='navbar.navItem2'></Translate>
        </Link>
        <Link
          to='/jarataink'
          className='menuItem basicFlex'
          //   data-target='dropdown1'
          style={{ textDecoration: 'none', color: '#505050' }}>
          <Translate value='navbar.navItem3'></Translate>
          {/* <i className='material-icons right'>arrow_drop_down</i> */}
        </Link>
        <Link
          to='/gepkocsipark'
          className='menuItem basicFlex'
          style={{ textDecoration: 'none', color: '#505050' }}>
          <Translate value='navbar.navItem4'></Translate>
        </Link>
        <Link
          to='/raktarozas'
          className='menuItem  basicFlex'
          style={{ textDecoration: 'none', color: '#505050' }}>
          <Translate value='navbar.navItem5'></Translate>
        </Link>
        <Link
          to='/galeria'
          className='menuItem basicFlex'
          style={{ textDecoration: 'none', color: '#505050' }}>
          <Translate value='navbar.navItem6'></Translate>
        </Link>
        {/* <Link
          to='/kuldetesi-nyilatkozat'
          className='menuItem basicFlex'
          style={{ textDecoration: 'none', color: '#505050' }}>
          <Translate value='navbar.navItem7'></Translate>
        </Link> */}
        <Link
          to='/karrier'
          className='menuItem  basicFlex'
          //   data-target='dropdown2'
          style={{
            display: props.locale === 'en' ? 'none' : '',
            textDecoration: 'none',
            color: '#505050',
          }}>
          <Translate value='navbar.navItem8'></Translate>
          {/* <i className='material-icons right'>arrow_drop_down</i> */}
        </Link>
        <Link
          to='/kapcsolat'
          className='menuItem  basicFlex'
          style={{ textDecoration: 'none', color: '#505050' }}>
          <Translate value='navbar.navItem9'></Translate>
        </Link>
      </div>
    </ul>
  );
};
const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
});
export default connect(mapStateToProps)(MobileNavBar);
