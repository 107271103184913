import React from 'react';
import { Slide, Slider, Caption } from 'react-materialize';
import { Translate } from 'react-redux-i18n';

const TransferPageSlider = (props) => {
  const { slide } = props;
  return (
    <Slider
      className='smallSlider'
      fullscreen={false}
      options={{
        duration: 500,
        height: 400,
        indicators: true,
        interval: 6000,
      }}>
      <Slide
        image={
          <img
            alt='headerImg'
            src='/img/destinationHeader/destinationHeader5.jpg'
          />
        }>
        <Caption placement='left'>
          <p className='slideTitle'>
            <Translate value={`imgSlider.${slide}`}></Translate>
          </p>
        </Caption>
      </Slide>
      <Slide
        image={
          <img
            alt='headerImg'
            src='/img/destinationHeader/destinationHeader4.jpg'
          />
        }></Slide>
      <Slide
        image={
          <img
            alt='headerImg'
            src='/img/destinationHeader/destinationHeader3.jpg'
          />
        }></Slide>
      <Slide
        image={
          <img
            alt='headerImg'
            src='/img/destinationHeader/destinationHeader2.jpg'
          />
        }></Slide>
      <Slide
        image={
          <img
            alt='headerImg'
            src='/img/destinationHeader/destinationHeader1.jpg'
          />
        }></Slide>
      <Slide
        image={
          <img
            alt='headerImg'
            src='/img/destinationHeader/destinationHeader.jpg'
          />
        }></Slide>
      <Slide
        image={
          <img
            alt='headerImg'
            src='/img/destinationHeader/destinationHeader6.jpeg'
          />
        }></Slide>
      <Slide
        image={
          <img
            alt='headerImg'
            src='/img/destinationHeader/destinationHeader7.jpg'
          />
        }></Slide>
    </Slider>
  );
};

export default TransferPageSlider;
