import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import SmallSlider from '../layout/SmallSlider';

const StorageDepo = (props) => {
  return (
    <Fragment>
      {props.locale === undefined ? (
        <div className='container'>
          <div class='progress'>
            <div class='indeterminate'></div>
          </div>
        </div>
      ) : (
        <Fragment>
          <SmallSlider img='raktarozas' placement='center' slide='slide4' />
          <div className='container'>
            <div className='description '>
              <Translate value='storage.description.part1' /> <br />
              <p className='listTitle'>
                <Translate value='storage.listTitles.title1' />
              </p>
              <ul>
                <li className='companyItemList'>
                  <Translate value='storage.list1.listItem1' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list1.listItem2' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list1.listItem3' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list1.listItem4' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list1.listItem5' />
                </li>
              </ul>
              <p className='listTitle'>
                <Translate value='storage.listTitles.title2' />
              </p>
              <ul>
                <li className='companyItemList'>
                  <Translate value='storage.list2.listItem1' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list2.listItem2' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list2.listItem3' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list2.listItem4' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list2.listItem5' />
                </li>
              </ul>
              <p className='listTitle'>
                <Translate value='storage.listTitles.title3' />
              </p>
              <ul>
                <li className='companyItemList'>
                  <Translate value='storage.list3.listItem1' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list3.listItem2' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list3.listItem3' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list3.listItem4' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list3.listItem5' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list3.listItem6' />
                </li>
              </ul>
              <p className='listTitle'>
                <Translate value='storage.listTitles.title4' />
              </p>
              <ul>
                <li className='companyItemList'>
                  <Translate value='storage.list4.listItem1' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list4.listItem2' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list4.listItem3' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list4.listItem4' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list4.listItem5' />
                </li>
                <li className='companyItemList'>
                  <Translate value='storage.list4.listItem6' />
                </li>
              </ul>
              <br />
              <Translate value='storage.description.part2' /> <br />
              <Translate value='storage.description.part3' />
            </div>
            <br />
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5379.714945525792!2d19.110219!3d47.609461!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741d0b7b0e416c3%3A0x3f878f42af003eff!2zRHVuYWtlc3ppLCBTesOpa2VzZMWxbMWRIHNvciAxMzUsIDIxMjA!5e0!3m2!1shu!2shu!4v1519621908724'
              width='100%'
              title='map'
              height='520'
              frameBorder='0'
              allowFullScreen=''></iframe>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
});
export default connect(mapStateToProps)(StorageDepo);
