import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import SmallSlider from '../layout/SmallSlider';

const tableDataHu = [
  {
    teher: '0,7',
    raklap: 3,
    ládaméret: '1,2',
    adr: 'igen',
    felépítmény: 'furgon',
  },
  {
    teher: '0,7',
    raklap: 10,
    ládaméret: '4,1',
    adr: 'igen',
    felépítmény: 'ponyvás',
  },
  {
    teher: '2,7',
    raklap: 15,
    ládaméret: '6,2',
    adr: 'igen',
    felépítmény: 'dobozos + hátfalas',
  },
  {
    teher: '5,3',
    raklap: 18,
    ládaméret: '7,2',
    adr: 'igen',
    felépítmény: 'ponyvás + hátfalas',
  },
  {
    teher: '5,8',
    raklap: 18,
    ládaméret: '7,2',
    adr: 'igen',
    felépítmény: 'rolóponyvás',
  },
  {
    teher: '5,8',
    raklap: 19,
    ládaméret: '7,6',
    adr: 'igen',
    felépítmény: 'rolóponyvás, daruzható',
  },
  {
    teher: '24',
    raklap: 33,
    ládaméret: '13,6',
    adr: 'igen',
    felépítmény: 'rolóponyvás, mega, daruzható',
  },
  {
    teher: '23',
    raklap: 66,
    ládaméret: '13,6',
    adr: 'igen',
    felépítmény: 'rolóponyvás, mega, dupla szintes, daruzható',
  },
];
const tableDataEn = [
  {
    teher: '0,7',
    raklap: 3,
    ládaméret: '1,2',
    adr: 'yes',
    felépítmény: 'van',
  },
  {
    teher: '0,7',
    raklap: 10,
    ládaméret: '4,1',
    adr: 'yes',
    felépítmény: 'curtain slider',
  },
  {
    teher: '2,7',
    raklap: 15,
    ládaméret: '6,2',
    adr: 'yes',
    felépítmény: 'boxed + back wall',
  },
  {
    teher: '5,3',
    raklap: 18,
    ládaméret: '7,2',
    adr: 'yes',
    felépítmény: 'curtain slider + box wall',
  },
  {
    teher: '5,8',
    raklap: 18,
    ládaméret: '7,2',
    adr: 'yes',
    felépítmény: 'roller blinds',
  },
  {
    teher: '5,8',
    raklap: 19,
    ládaméret: '7,6',
    adr: 'yes',
    felépítmény: 'tautliner, crane',
  },
  {
    teher: '24',
    raklap: 33,
    ládaméret: '13,6',
    adr: 'yes',
    felépítmény: 'tautliner, mega, crane',
  },
  {
    teher: '23',
    raklap: 66,
    ládaméret: '13,6',
    adr: 'yes',
    felépítmény: 'tautliner, mega double deck, crane',
  },
];
const CarPark = (props) => {
  return (
    <Fragment>
      {props.locale === undefined ? (
        <div className='container'>
          <div class='progress'>
            <div class='indeterminate'></div>
          </div>
        </div>
      ) : (
        <Fragment>
          <SmallSlider img='gepkocsipark' placement='left' slide='slide3' />
          <div className='container'>
            <p className='description '>
              <Translate value='fleet.description.part1' />
              <br />
              <Translate value='fleet.description.part2' />
              <br />
              <Translate value='fleet.description.part3' />
            </p>

            <p className='introductionTitle' style={{ marginTop: '20px' }}>
              <Translate value='fleet.description.part4' />
            </p>
            <table className='centered responsive-table'>
              <thead className='tableHead'>
                <tr>
                  <th>
                    <Translate value='fleet.tableTitles.title1' />
                  </th>
                  <th>
                    <Translate value='fleet.tableTitles.title2' />
                  </th>
                  <th>
                    <Translate value='fleet.tableTitles.title3' />
                  </th>
                  <th>
                    <Translate value='fleet.tableTitles.title4' />
                  </th>
                  <th>
                    <Translate value='fleet.tableTitles.title5' />
                  </th>
                </tr>
              </thead>

              <tbody>
                {props.locale === undefined ? (
                  <div className='preloader-wrapper big active'>
                    <div className='spinner-layer spinner-blue-only'>
                      <div className='circle-clipper left'>
                        <div className='circle'></div>
                      </div>
                      <div className='gap-patch'>
                        <div className='circle'></div>
                      </div>
                      <div className='circle-clipper right'>
                        <div className='circle'></div>
                      </div>
                    </div>
                  </div>
                ) : props.locale === 'en' ? (
                  tableDataEn.map((data, index) => (
                    <tr key={index}>
                      <td>{data.teher}</td>
                      <td>{data.raklap}</td>
                      <td>{data.ládaméret}</td>
                      <td>{data.adr}</td>
                      <td>{data.felépítmény}</td>
                    </tr>
                  ))
                ) : (
                  tableDataHu.map((data, index) => (
                    <tr key={index}>
                      <td>{data.teher}</td>
                      <td>{data.raklap}</td>
                      <td>{data.ládaméret}</td>
                      <td>{data.adr}</td>
                      <td>{data.felépítmény}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
});
export default connect(mapStateToProps)(CarPark);
// {
//   tableData.map((data, index) => (
//     <tr key={index}>
//       <td>{data.teher}</td>
//       <td>{data.raklap}</td>
//       <td>{data.ládaméret}</td>
//       <td>{data.adr}</td>
//       <td>{data.felépítmény}</td>
//     </tr>
//   ));
// }
