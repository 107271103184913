import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import ImgSlider from '../layout/ImgSlider';
import { Translate } from 'react-redux-i18n';

const HomePage = (props) => {
  useEffect(() => {
    console.log(props.locale);
  }, [props.locale]);
  return (
    <Fragment>
      {props.locale === undefined ? (
        <div className='container'>
          <div class='progress'>
            <div class='indeterminate'></div>
          </div>
        </div>
      ) : (
        <Fragment>
          <ImgSlider />
          <div className='container mainPageContainer'>
            <p className='introductionTitle'>
              <Translate value='introduction.title' />
            </p>
            <div className='description'>
              <Translate value='introduction.description.part1' />
              <br />
              <p className='listTitle'>
                <Translate value='introduction.description.part2' />
              </p>

              <ul>
                <li className='companyItemList'>
                  <Translate value='introduction.description.companyInfoList.listItem1' />
                </li>
                <li className='companyItemList'>
                  <Translate value='introduction.description.companyInfoList.listItem2' />
                </li>
                <li className='companyItemList'>
                  <Translate value='introduction.description.companyInfoList.listItem3' />
                </li>
                <li className='companyItemList'>
                  <Translate value='introduction.description.companyInfoList.listItem4' />
                </li>
                <li className='companyItemList'>
                  <Translate value='introduction.description.companyInfoList.listItem5' />
                </li>
                <li className='companyItemList'>
                  <Translate value='introduction.description.companyInfoList.listItem6' />
                </li>
              </ul>
              <p className='listTitle'>
                <Translate value='introduction.description.part3' />
              </p>
              <ul>
                <li className='companyItemList'>
                  <Translate value='introduction.description.whatWeOfferList.listItem1' />
                </li>
                <li className='companyItemList'>
                  <Translate value='introduction.description.whatWeOfferList.listItem2' />
                </li>
                <li className='companyItemList'>
                  <Translate value='introduction.description.whatWeOfferList.listItem3' />
                </li>
                <li className='companyItemList'>
                  <Translate value='introduction.description.whatWeOfferList.listItem4' />
                </li>
              </ul>

              <p className='listTitle'>
                <Translate value='introduction.description.part4' />
              </p>
              <p className='listTitle'>
                <Translate value='introduction.description.part5' />
              </p>
              <ul>
                <li className='companyItemList'>
                  <Translate value='introduction.description.mottoList.listItem1' />
                </li>
                <li className='companyItemList'>
                  <Translate value='introduction.description.mottoList.listItem2' />
                </li>
                <li className='companyItemList'>
                  <Translate value='introduction.description.mottoList.listItem3' />
                </li>
                <li className='companyItemList'>
                  <Translate value='introduction.description.mottoList.listItem4' />
                </li>
                <li className='companyItemList'>
                  <Translate value='introduction.description.mottoList.listItem5' />
                </li>
                <li className='companyItemList'>
                  <Translate value='introduction.description.mottoList.listItem6' />
                </li>
              </ul>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
});

export default connect(mapStateToProps)(HomePage);
