import React, { useState, Fragment } from 'react';
import SmallSlider from '../layout/SmallSlider';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';

const contactsHu = [
  [
    {
      type: 'main',
      title: 'Ügyvezető igazgató',
      name: 'Egyházy Attila',
      phone: '+36 20 472 7141',
      email: 'attila.egyhazy@ekytrans.hu',
    },
    {
      type: 'main',
      title: 'Irodavezető',
      name: 'Hargitai Júlia',
      phone: '+36 30 365 5612',
      email: 'julia.hargitai@ekytrans.hu',
    },
    {
      type: 'main',
      title: 'Műszaki vezető',
      name: 'Pribus Richárd',
      phone: '+36 20 998 4950',
      email: 'richard.pribus@ekytrans.hu',
    },
    {
      type: 'main',
      title: 'Pénzügyi vezető',
      name: 'Vajay-Horváth Ákos',
      phone: '+36 20 505 0135',
      email: 'akos.vajay@ekytrans.hu',
    },
    {
      type: 'main',
      title: 'HR vezető',
      name: 'Bekő Szilvia',
      phone: '+36 70 516 8978',
      email: 'szilvia.beko@ekytrans.hu',
    },
  ],
  [
    {
      type: 'warehouse',
      name: 'Béres Zoltán',
      phone: '+36 70 684 5535',
      email: 'zoltan.beres@ekytrans.hu',
    },
    {
      type: 'warehouse',
      name: 'Balogh Tamás',
      phone: '+36 70 368 2737',
      email: 'tamas.balogh@ekytrans.hu',
    },
  ],
  [
    {
      type: 'transport',
      territory: 'Svéd- finn-norvég fuvarszervező',
      name: 'Bori András',
      phone: '+36 20 472 0169',
      email: 'andras.bori@ekytrans.hu',
      skype: 'eky.andras',
      territoryImg: '/img/countries/sweedish_finn.svg',
    },
    {
      type: 'transport',
      territory: 'Dánia, Benelux államok',
      name: 'Boldizsár András',
      phone: '+36 20 886 8542',
      email: 'andras.boldizsar@ekytrans.hu',
      skype: 'eky-jonny',
      territoryImg: '/img/countries/danish.svg',
    },
    {
      type: 'transport',
      territory: 'Anglia, Írország',
      name: 'Balk Gábor',
      phone: '+36 70 337 7540',
      email: 'gabor.balk@ekytrans.hu',
      skype: 'live:.cid.87fc6e81bd18a1df',
      territoryImg: '/img/countries/english.svg',
    },
    {
      type: 'transport',
      territory: 'Belföld és szomszédos országok',
      name: 'Simon Dorka',
      phone: '+36 20 361 0359',
      email: 'dorka.simon@ekytrans.hu',
      skype: 'live:.cid.2d580059dc274da3',
      territoryImg: '/img/countries/internal.svg',
    },
    {
      type: 'transport',
      territory: 'Belföld és szomszédos országok',
      name: 'Lőczi Attila',
      phone: '+36 70 516 8979',
      email: 'attila.loczi@ekytrans.hu',
      skype: 'live:.cid.f7227e9f22812ca7',
      territoryImg: '/img/countries/internal.svg',
    },
  ],
];
const contactsEn = [
  [
    {
      type: 'main',
      title: 'Executive Director',
      name: 'Attila Egyházy',
      phone: '+36 20 472 7141',
      email: 'attila.egyhazy@ekytrans.hu',
    },
    {
      type: 'main',
      title: 'Office Manager',
      name: 'Júlia Hargitai',
      phone: '+36 30 365 5612',
      email: 'julia.hargitai@ekytrans.hu',
    },
    {
      type: 'main',
      title: 'Technical Manager',
      name: ' Richárd Pribus',
      phone: '+36 20 998 4950',
      email: 'richard.pribus@ekytrans.hu',
    },
    {
      type: 'main',
      title: 'Finance Manager',
      name: 'Ákos Vajay-Horváth',
      phone: '+36 20 505 0135',
      email: 'akos.vajay@ekytrans.hu',
    },
    {
      type: 'main',
      title: 'HR Manager',
      name: 'Szilvia Bekő',
      phone: '+36 70 516 8978',
      email: 'szilvia.beko@ekytrans.hu',
    },
  ],
  [
    {
      type: 'warehouse',
      name: 'Zoltán Béres',
      phone: '+36 70 684 5535',
      email: 'zoltan.beres@ekytrans.hu',
    },
    {
      type: 'warehouse',
      name: 'Tamás Balogh',
      phone: '++36 70 368 2737',
      email: 'tamas.balogh@ekytrans.hu',
    },
  ],
  [
    {
      type: 'transport',
      territory: 'Sweden, Norway, Finland',
      name: 'András Bori ',
      phone: '+36 20 472 0169',
      email: 'andras.bori@ekytrans.hu',
      skype: 'eky.andras',
      territoryImg: '/img/countries/sweedish_finn.svg',
    },
    {
      type: 'transport',
      territory: 'Denmark, Benelux Union ',
      name: 'András Boldizsár',
      phone: '+36 20 886 8542',
      email: 'andras.boldizsar@ekytrans.hu',
      skype: 'eky-jonny',
      territoryImg: '/img/countries/danish.svg',
    },
    {
      type: 'transport',
      territory: 'United Kingdom, Irland',
      name: 'Gábor Balk',
      phone: '+36 70 337 7540',
      email: 'gabor.balk@ekytrans.hu',
      skype: 'live:.cid.87fc6e81bd18a1df',
      territoryImg: '/img/countries/english.svg',
    },
    {
      type: 'transport',
      territory: 'Hungary and Central Europe',
      name: 'Dorka Simon',
      phone: '+36 20 361 0359',
      email: 'dorka.simon@ekytrans.hu',
      skype: 'live:.cid.2d580059dc274da3',
      territoryImg: '/img/countries/internal.svg',
    },
    {
      type: 'transport',
      territory: 'Hungary and Central Europe',
      name: 'Attila Lőczi',
      phone: '+36 70 516 8979',
      email: 'attila.loczi@ekytrans.hu',
      skype: 'live:.cid.f7227e9f22812ca7',
      territoryImg: '/img/countries/internal.svg',
    },
  ],
];

const Contact = (props) => {
  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: '',
    textArea: '',
  });
  const { name, email, phone, textArea } = user;
  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (evt) => {
    evt.preventDefault();

    let url = 'http://www.ekytrans.hu/contact.php';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        from: email,
        name: name,
        phone: phone,
        text: textArea,
      }),
    }).catch((error) => console.log(error));
  };
  return (
    <Fragment>
      {props.locale === undefined ? (
        <div className='container'>
          <div className='progress'>
            <div className='indeterminate'></div>
          </div>
        </div>
      ) : (
        <Fragment>
          <SmallSlider img='contact2' placement='right' slide='slide7' />
          <div className='container '>
            <div className='contactsMainContainer'>
              <div className='contactsContainer'>
                <p className='description'>
                  Eky-Light Kereskedelmi és Szolgáltató Kft. <br />
                  Székhely és levelezési cím: H-1045 Budapest, Ódry Árpád utca
                  3.
                  <br />
                  Telephely: 2120 Dunakeszi, Pallag utca 4.
                  <br />
                  GPS koordináta: 47.609213, 19.111868
                  <br />
                  Adószám: 13249456-2-41
                  <br />
                  Cégjegyzékszám: 01-09-725760
                  <br />
                  Forint bankszámlaszám: Budapest Bank
                  <br />
                  HU94 10101346-44307300-01005001 (HUF)
                  <br />
                  Deviza számlaszám: OTP Banka Slovensko SK84 5200-0000-
                  0000-1511-9098
                  <br />
                  Font számlaszám: SK54 5200 0000 0000 1885 2295
                  <br />
                  SWIFT kód: OTPVSKBX
                  <br />
                  TimoCom: 113975
                  <br />
                  Belföldi- és nemzetközi szállítmányozás:
                  http://www.ekytrans.hu
                </p>
              </div>

              <div className='contactsForm'>
                <form onSubmit={handleSubmit}>
                  <label htmlFor='name'>
                    <Translate value='form.name' />
                    <input
                      type='text'
                      name='name'
                      value={name}
                      onChange={onChange}
                    />
                  </label>
                  <label htmlFor='email'>
                    <Translate value='form.email' />
                    <input
                      type='email'
                      name='email'
                      value={email}
                      onChange={onChange}
                    />
                  </label>
                  <label htmlFor='phone'>
                    <Translate value='form.phone' />
                    <input
                      type='text'
                      name='phone'
                      value={phone}
                      onChange={onChange}
                    />
                  </label>
                  <label htmlFor='textarea'>
                    <Translate value='form.text' />
                  </label>
                  <textarea
                    name='textArea'
                    value={textArea}
                    onChange={onChange}></textarea>
                  <div className='formHandlerButtons contactSendButton'>
                    <input
                      type='submit'
                      className='formHandlerButton '
                      value={props.locale === 'hu' ? 'Küldés' : 'Send'}
                    />
                  </div>
                </form>
              </div>
            </div>

            <div className='mainContacts'>
              <h4>{props.locale === 'hu' ? 'Kapcsolat' : 'Contact'}</h4>
              <div className='maincontCardCont'>
                {props.locale === undefined
                  ? null
                  : props.locale === 'en'
                  ? contactsEn[0].map((contact) => (
                      <div className='contactCard contactCardSize z-depth-2 '>
                        <div className='contactContainer'>
                          <p className='position'>{contact.title}</p>
                          <p className='contactName'>{contact.name}</p>
                          <div className='contactItemCont basicFlex'>
                            <img
                              src='/img/contactIcons/number.svg'
                              alt='phone'
                            />
                            <p>{contact.phone}</p>
                          </div>
                          <div className='contactItemCont basicFlex'>
                            <img
                              src='/img/contactIcons/email.svg'
                              alt='email'
                            />
                            <p>{contact.email}</p>
                          </div>
                        </div>
                      </div>
                    ))
                  : contactsHu[0].map((contact) => (
                      <div className='contactCard contactCardSize z-depth-2 '>
                        <div className='contactContainer'>
                          <p className='position'>{contact.title}</p>
                          <p className='contactName'>{contact.name}</p>
                          <div className='contactItemCont basicFlex'>
                            <img
                              src='/img/contactIcons/number.svg'
                              alt='phone'
                            />
                            <p>{contact.phone}</p>
                          </div>
                          <div className='contactItemCont basicFlex'>
                            <img
                              src='/img/contactIcons/email.svg'
                              alt='email'
                            />
                            <p>{contact.email}</p>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
            <div className='warehouseContacts'>
              <h4>{props.locale === 'hu' ? 'Raktár' : 'Warehouse'}</h4>
              <div className='wareHouseCardCont'>
                {props.locale === undefined
                  ? null
                  : props.locale === 'en'
                  ? contactsEn[1].map((contact) => (
                      <div className='contactCard contactCardSize z-depth-2 '>
                        <div className='contactContainer'>
                          <p className='position'>{contact.title}</p>
                          <p className='contactName'>{contact.name}</p>
                          <div className='contactItemCont basicFlex'>
                            <img
                              src='/img/contactIcons/number.svg'
                              alt='phone'
                            />
                            <p>{contact.phone}</p>
                          </div>
                          <div className='contactItemCont basicFlex'>
                            <img
                              src='/img/contactIcons/email.svg'
                              alt='email'
                            />
                            <p>{contact.email}</p>
                          </div>
                        </div>
                      </div>
                    ))
                  : contactsHu[1].map((contact) => (
                      <div className='contactCard contactCardSize z-depth-2 '>
                        <div className='contactContainer'>
                          <p className='position'>{contact.title}</p>
                          <p className='contactName'>{contact.name}</p>
                          <div className='contactItemCont basicFlex'>
                            <img
                              src='/img/contactIcons/number.svg'
                              alt='phone'
                            />
                            <p>{contact.phone}</p>
                          </div>
                          <div className='contactItemCont basicFlex'>
                            <img
                              src='/img/contactIcons/email.svg'
                              alt='email'
                            />
                            <p>{contact.email}</p>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
            <div className='transferContacts'>
              <h4>
                {props.locale === 'hu'
                  ? 'Fuvarszervezők'
                  : 'Transport Planners'}
              </h4>
              <div className='transferCardCont'>
                {props.locale === undefined
                  ? null
                  : props.locale === 'en'
                  ? contactsEn[2].map((contact) => (
                      <div className='contactCard contactCardSize z-depth-2 '>
                        <div className='contactContainer'>
                          <div className='flexCont'>
                            <div>
                              <p className='position'>{contact.territory}</p>
                              <p className='contactName'>{contact.name}</p>
                              <div className='contactItemCont basicFlex'>
                                <img
                                  src='/img/contactIcons/number.svg'
                                  alt='phone'
                                />
                                <p>{contact.phone}</p>
                              </div>
                              <div className='contactItemCont basicFlex'>
                                <img
                                  src='/img/contactIcons/email.svg'
                                  alt='email'
                                />
                                <p>{contact.email}</p>
                              </div>
                            </div>
                            <div className='pictureCont'>
                              <img
                                src={contact.territoryImg}
                                alt='countrie'
                                style={{ height: '108px', width: '90px' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : contactsHu[2].map((contact) => (
                      <div className='contactCard contactCardSize z-depth-2 '>
                        <div className='contactContainer'>
                          <div className='flexCont'>
                            <div>
                              <p className='position'>{contact.territory}</p>
                              <p className='contactName'>{contact.name}</p>
                              <div className='contactItemCont basicFlex'>
                                <img
                                  src='/img/contactIcons/number.svg'
                                  alt='phone'
                                />
                                <p>{contact.phone}</p>
                              </div>
                              <div className='contactItemCont basicFlex'>
                                <img
                                  src='/img/contactIcons/email.svg'
                                  alt='email'
                                />
                                <p>{contact.email}</p>
                              </div>
                            </div>
                            <div className='pictureCont'>
                              <img
                                src={contact.territoryImg}
                                alt='countrie'
                                style={{ height: '108px', width: '90px' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5379.714945525792!2d19.110219!3d47.609461!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741d0b7b0e416c3%3A0x3f878f42af003eff!2zRHVuYWtlc3ppLCBTesOpa2VzZMWxbMWRIHNvciAxMzUsIDIxMjA!5e0!3m2!1shu!2shu!4v1519621908724'
              width='100%'
              title='map'
              height='520'
              frameBorder='0'
              allowFullScreen=''></iframe>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
});
export default connect(mapStateToProps)(Contact);
