import React from 'react';
import { Slide, Slider, Caption } from 'react-materialize';

const ThankYou = () => {
  return (
    <Slider
      fullscreen={false}
      options={{
        duration: 500,
        height: 800,
        indicators: false,
        interval: 6000,
      }}>
      <Slide image={<img alt='headerImg' src={`/img/thanksBG.jpg`} />}>
        <Caption placement='center' className='basicFlex firstSlide'>
          <p className='slideTitle'>Köszönjük jelentkezésedet! </p>
          <div className='customButton basicFlex'>Vissza a főoldalra</div>
        </Caption>
      </Slide>
    </Slider>
  );
};

export default ThankYou;
