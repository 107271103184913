import React, { Fragment } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Translate } from 'react-redux-i18n';
import TransferPageSlider from '../layout/TransferPageSlider';
import { connect } from 'react-redux';

const contactsHU = [
  {
    territory: 'Svéd- finn-norvég fuvarszervező',
    name: 'Bori András',
    phone: '+36 20 472 0169',
    email: 'andras.bori@ekytrans.hu',
    skype: 'eky.andras',
    territoryImg: '/img/countries/sweedish_finn.svg',
  },
  {
    territory: 'Dánia, Benelux államok',
    name: 'Boldizsár András',
    phone: '+36 20 886 8542',
    email: 'andras.boldizsar@ekytrans.hu',
    skype: 'eky-jonny',
    territoryImg: '/img/countries/danish.svg',
  },
  {
    territory: 'Anglia, Írország',
    name: 'Balk Gábor',
    phone: '+36 70 3377540',
    email: 'gabor.balk@ekytrans.hu',
    skype: 'live:.cid.87fc6e81bd18a1df',
    territoryImg: '/img/countries/english.svg',
  },
  {
    territory: 'Belföld és szomszédos országok',
    name: 'Simon Dorka',
    phone: '+36 20 361 0359',
    email: 'dorka.simon@ekytrans.hu',
    skype: 'live:.cid.2d580059dc274da3',
    territoryImg: '/img/countries/internal.svg',
  },
  {
    territory: 'Belföld és szomszédos országok',
    name: 'Lőczi Attila',
    phone: '+36 70 516 8979',
    email: 'attila.loczi@ekytrans.hu',
    skype: 'live:.cid.f7227e9f22812ca7',
    territoryImg: '/img/countries/internal.svg',
  },
];
const contactsEN = [
  {
    territory: 'Sweden, Norway, Finland',
    name: 'András Bori',
    phone: '+36 20 472 0169',
    email: 'andras.bori@ekytrans.hu',
    skype: 'eky.andras',
    territoryImg: '/img/countries/sweedish_finn.svg',
  },
  {
    territory: 'Denmark, Benelux Union',
    name: 'András Boldizsár',
    phone: '+36 20 886 8542',
    email: 'andras.boldizsar@ekytrans.hu',
    skype: 'eky-jonny',
    territoryImg: '/img/countries/danish.svg',
  },
  {
    territory: 'United Kingdom, Irland',
    name: 'Gábor Balk',
    phone: '+36 70 3377540',
    email: 'gabor.balk@ekytrans.hu',
    skype: 'live:.cid.87fc6e81bd18a1df',
    territoryImg: '/img/countries/english.svg',
  },
  {
    territory: 'Hungary and Central Europe',
    name: 'Dorka Simon',
    phone: '+36 20 361 0359',
    email: 'dorka.simon@ekytrans.hu',
    skype: 'live:.cid.2d580059dc274da3',
    territoryImg: '/img/countries/internal.svg',
  },
  {
    territory: 'Hungary and Central Europe',
    name: 'Attila Lőczi',
    phone: '+36 70 516 8979',
    email: 'attila.loczi@ekytrans.hu',
    skype: 'live:.cid.f7227e9f22812ca7',
    territoryImg: '/img/countries/internal.svg',
  },
];
const CustomButtonGroup = ({ next, previous, ...rest }) => {
  return (
    <div className='carousel-button-group '>
      <img src='/img/arrow.svg' alt='left' onClick={() => previous()} />
      <img
        src='/img/arrow.svg'
        alt='right'
        onClick={() => next()}
        style={{ transform: 'rotate(180deg)' }}
      />
    </div>
  );
};

const TransferPage = (props) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1800 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1200 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1200, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Fragment>
      {props.locale === undefined ? (
        <div className='container'>
          <div className='progress'>
            <div className='indeterminate'></div>
          </div>
        </div>
      ) : (
        <Fragment>
          <TransferPageSlider slide='slide2' />
          <div className='container'>
            <div className='description' style={{ marginBottom: '60px' }}>
              <p className='introductionTitle'>
                <Translate value='destination.title' />
              </p>
              <ul>
                <li className='companyItemList'>
                  <Translate value='destination.countryList.listItem1' />
                </li>
                <li className='companyItemList'>
                  <Translate value='destination.countryList.listItem2' />
                </li>
                <li className='companyItemList'>
                  <Translate value='destination.countryList.listItem3' />
                </li>
                <li className='companyItemList'>
                  <Translate value='destination.countryList.listItem4' />
                </li>
                <li className='companyItemList'>
                  <Translate value='destination.countryList.listItem5' />
                </li>
              </ul>

              <Translate value='destination.description.part1' />
              <br />
              <Translate value='destination.description.part2' />
            </div>
            <div className='mainCarrouselCont'>
              <Carousel
                arrows={false}
                className='carrouselCont'
                responsive={responsive}
                infinite={true}
                focusOnSelect={false}
                renderButtonGroupOutside={true}
                customButtonGroup={<CustomButtonGroup />}
                autoPlay={true}
                autoPlaySpeed={2000}
                transitionDuration={600}>
                {/* {contacts.map((contact) => (
                  <div className='cardTest card' key={contact.name}>
                    <div className='card-image'>
                      <img
                        src={contact.territoryImg}
                        alt='country'
                        className='countryPic'
                      />
                    </div>
                    <div className='card-content'>
                      <span className='card-title'>{contact.territory}:</span>
                      <div className='contact'>
                        <p>{contact.name}</p>
                        <div className='contactItemCont basicFlex'>
                          <img src='/img/contactIcons/number.svg' alt='phone' />
                          <p>{contact.phone}</p>
                        </div>
                        <div className='contactItemCont basicFlex'>
                          <img src='/img/contactIcons/email.svg' alt='email' />
                          <p>{contact.email}</p>
                        </div>
                        <div className='contactItemCont basicFlex'>
                          <img src='/img/contactIcons/skype.svg' alt='skype' />
                          <a href={`skype:${contact.skype}?chat`}>Call</a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}
                {props.locale === 'hu'
                  ? contactsHU.map((contact) => (
                      <div className='cardTest card' key={contact.name}>
                        <div className='card-image'>
                          <img
                            src={contact.territoryImg}
                            alt='country'
                            className='countryPic'
                          />
                        </div>
                        <div className='card-content'>
                          <span className='card-title'>
                            {contact.territory}:
                          </span>
                          <div className='contact'>
                            <p>{contact.name}</p>
                            <div className='contactItemCont basicFlex'>
                              <img
                                src='/img/contactIcons/number.svg'
                                alt='phone'
                              />
                              <p>{contact.phone}</p>
                            </div>
                            <div className='contactItemCont basicFlex'>
                              <img
                                src='/img/contactIcons/email.svg'
                                alt='email'
                              />
                              <p>{contact.email}</p>
                            </div>
                            <div className='contactItemCont basicFlex'>
                              <img
                                src='/img/contactIcons/skype.svg'
                                alt='skype'
                              />
                              <a href={`skype:${contact.skype}?chat`}>Call</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : contactsEN.map((contact) => (
                      <div className='cardTest card' key={contact.name}>
                        <div className='card-image'>
                          <img
                            src={contact.territoryImg}
                            alt='country'
                            className='countryPic'
                          />
                        </div>
                        <div className='card-content'>
                          <span className='card-title'>
                            {contact.territory}:
                          </span>
                          <div className='contact'>
                            <p>{contact.name}</p>
                            <div className='contactItemCont basicFlex'>
                              <img
                                src='/img/contactIcons/number.svg'
                                alt='phone'
                              />
                              <p>{contact.phone}</p>
                            </div>
                            <div className='contactItemCont basicFlex'>
                              <img
                                src='/img/contactIcons/email.svg'
                                alt='email'
                              />
                              <p>{contact.email}</p>
                            </div>
                            <div className='contactItemCont basicFlex'>
                              <img
                                src='/img/contactIcons/skype.svg'
                                alt='skype'
                              />
                              <a href={`skype:${contact.skype}?chat`}>Call</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </Carousel>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
});
export default connect(mapStateToProps)(TransferPage);
