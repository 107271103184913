import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import SmallSlider from '../layout/SmallSlider';

const Career = (props) => {
  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: '',
    textArea: '',
  });
  const { name, email, phone, textArea } = user;
  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (evt) => {
    evt.preventDefault();
    let file = document.getElementById('upload').files[0];
    let formData = new FormData();
    formData.append('upload', file);
    formData.append('from', email);
    formData.append('name', name);
    formData.append('phone', phone);
    formData.append('text', textArea);
    console.log({ file, email, name, phone, textArea });
    let url = 'http://www.ekytrans.hu/career.php';
    fetch(url, {
      method: 'POST',
      body: formData,
    }).catch((error) => console.log(error));
  };
  return (
    <Fragment>
      <SmallSlider img='karrier' placement='center' slide='slide6' />
      <div className='container'>
        <p className='introductionTitle' style={{ marginTop: '20px' }}>
          <Translate value='career.title' />
        </p>
        <p className='description'>
          <Translate value='career.current.title' />
          <Translate
            style={{ fontWeight: 'bold', color: 'black', fontSize: '15px' }}
            value='career.current.position'
          />
          <Translate value='career.current.part1' />
        </p>

        <p className='description'>
          <Translate value='career.description.part1' /> <br />
          <Translate value='career.description.part2' /> <br />
          <Translate value='career.description.part3' /> <br />
        </p>
        <div className='description'>
          <p className='listTitle'>
            <Translate value='career.req.title' />
          </p>
          <ul>
            <li className='companyItemList'>
              <Translate value='career.req.part1' />
            </li>
            <li className='companyItemList'>
              <Translate value='career.req.part2' />
            </li>
            <li className='companyItemList'>
              <Translate value='career.req.part3' />
            </li>
            <li className='companyItemList'>
              <Translate value='career.req.part4' />
            </li>
            <li className='companyItemList'>
              <Translate value='career.req.part5' />
            </li>
            <li className='companyItemList'>
              <Translate value='career.req.part6' />
            </li>
            <li className='companyItemList'>
              <Translate value='career.req.part7' />
            </li>
          </ul>

          <p className='listTitle'>
            <Translate value='career.offer.title' />
          </p>
          <ul>
            <li className='companyItemList'>
              <Translate value='career.offer.part1' />
            </li>
            <li className='companyItemList'>
              <Translate value='career.offer.part2' />
            </li>
            <li className='companyItemList'>
              <Translate value='career.offer.part3' />
            </li>
            <li className='companyItemList'>
              <Translate value='career.offer.part4' />
            </li>
            <li className='companyItemList'>
              <Translate value='career.offer.part5' />
            </li>
          </ul>

          <p className='listTitle'>
            <Translate value='career.exp.title' />
          </p>
          <ul>
            <li className='companyItemList'>
              <Translate value='career.exp.part1' />
            </li>
          </ul>
          <p className='listTitle'>
            <Translate value='career.lang.title' />
          </p>
          <ul>
            <li className='companyItemList'>
              <Translate value='career.lang.part1' />
            </li>
          </ul>
          <p className='listTitle'>
            <Translate value='career.others.title' />
          </p>
          <ul>
            <li className='companyItemList'>
              <Translate value='career.others.part1' />
            </li>
            <li className='companyItemList'>
              <Translate value='career.others.part2' />
            </li>
          </ul>
        </div>

        <div className='contactCard z-depth-2 '>
          <div className='contactContainer'>
            <p className='contactName'>Bekő Szilvia</p>
            <div className='contactItemCont basicFlex'>
              <img src='/img/contactIcons/number.svg' alt='phone' />
              <p>+36 70 516 8978</p>
            </div>
            <div className='contactItemCont basicFlex'>
              <img src='/img/contactIcons/email.svg' alt='email' />
              <p>szilvia.beko@ekytrans.hu</p>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <label htmlFor='name'>
            <Translate value='form.name' />
            <input type='text' name='name' value={name} onChange={onChange} />
          </label>
          <label htmlFor='email'>
            <Translate value='form.email' />
            <input
              type='email'
              name='email'
              value={email}
              onChange={onChange}
            />
          </label>
          <label htmlFor='phone'>
            <Translate value='form.phone' />
            <input type='text' name='phone' value={phone} onChange={onChange} />
          </label>
          <label htmlFor='textarea'>
            <Translate value='form.text' />
          </label>
          <textarea
            name='textArea'
            value={textArea}
            onChange={onChange}></textarea>
          <div className='formHandlerButtons'>
            <label htmlFor='upload' className='formHandlerButton'>
              <Translate value='form.uploadDoc' />
            </label>

            <input type='file' name='upload' id='upload' />

            <input
              type='submit'
              className='formHandlerButton'
              value='Jelentkezés az állásra'
            />
          </div>
        </form>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
});
export default connect(mapStateToProps)(Career);
