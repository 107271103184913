import React from 'react';
import { Slide, Slider, Caption } from 'react-materialize';
import { Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

const ImgSlider = () => {
  return (
    <Slider
      fullscreen={false}
      options={{
        duration: 500,
        height: 700,
        indicators: true,
        interval: 6000,
      }}
    >
      <Slide
        className='test'
        // image={<img alt='' src='/img/headerPics/cimkep.jpg'}>
        image={<img alt='' src='/img/headerPics/cimkep.jpg' />}
      >
        <Caption placement='center' className='basicFlex firstSlide'>
          <p className='slideTitle' style={{ marginTop: '150px' }}>
            <Translate value='imgSlider.slide1' />
          </p>
          <div className='customButton basicFlex'>
            <Link
              to='/'
              style={{
                textDecoration: 'none',
                color: '#505050',
                fontSize: '16px !important',
                fontStyle: 'italic',
                fontWeight: 500,
              }}
            >
              <Translate value='imgSlider.button' />
            </Link>
          </div>
        </Caption>
      </Slide>
      <Slide image={<img alt='' src='/img/headerPics/routes.jpg' />}>
        <Caption placement='center' className='basicFlex firstSlide'>
          <p className='slideTitle'>
            <Translate value='imgSlider.slide2' />
          </p>
          <div className='customButton basicFlex'>
            <Link
              to='/jarataink'
              style={{
                textDecoration: 'none',
                color: '#505050',
                fontSize: '16px !important',
                fontStyle: 'italic',
                fontWeight: 500,
              }}
            >
              <Translate value='imgSlider.button' />
            </Link>
          </div>
        </Caption>
      </Slide>
      <Slide image={<img alt='' src='/img/headerPics/gepkocsipark.jpg' />}>
        <Caption placement='left'>
          <p className='slideTitle'>
            <Translate value='imgSlider.slide3' />
          </p>
          <div className='customButton basicFlex'>
            <Link
              to='/gepkocsipark'
              style={{
                textDecoration: 'none',
                color: '#505050',
                fontSize: '16px !important',
                fontStyle: 'italic',
                fontWeight: 500,
              }}
            >
              <Translate value='imgSlider.button' />
            </Link>
          </div>
        </Caption>
      </Slide>
      <Slide image={<img alt='' src='/img/headerPics/raktarozas.jpg' />}>
        <Caption placement='center' className='basicFlex firstSlide'>
          <p className='slideTitle'>
            <Translate value='imgSlider.slide4' />
          </p>
          <div className='customButton basicFlex'>
            <Link
              to='/raktarozas'
              style={{
                textDecoration: 'none',
                color: '#505050',
                fontSize: '16px !important',
                fontStyle: 'italic',
                fontWeight: 500,
              }}
            >
              <Translate value='imgSlider.button' />
            </Link>
          </div>
        </Caption>
      </Slide>
      <Slide image={<img alt='' src='/img/headerPics/customs.jpg' />}>
        <Caption placement='left'>
          <p className='slideTitle'>
            <Translate value='imgSlider.slide8' />
          </p>
          <div className='customButton basicFlex'>
            <Link
              to='/vamolas'
              style={{
                textDecoration: 'none',
                color: '#505050',
                fontSize: '16px !important',
                fontStyle: 'italic',
                fontWeight: 500,
              }}
            >
              <Translate value='imgSlider.button' />
            </Link>{' '}
          </div>
        </Caption>
      </Slide>
      <Slide image={<img alt='' src='/img/headerPics/gal.jpg' />}>
        <Caption
          placement='right'
          className='basicFlex firstSlide'
          style={{ alignItems: 'flex-end' }}
        >
          <p className='slideTitle'>
            <Translate value='imgSlider.slide5' />
          </p>
          <div className='customButton basicFlex'>
            <Link
              to='/galeria'
              style={{
                textDecoration: 'none',
                color: '#505050',
                fontSize: '16px !important',
                fontStyle: 'italic',
                fontWeight: 500,
              }}
            >
              <Translate value='imgSlider.button' />
            </Link>
          </div>
        </Caption>
      </Slide>
      <Slide image={<img alt='' src='/img/headerPics/karrier.jpg' />}>
        <Caption placement='left'>
          <p className='slideTitle'>
            <Translate value='imgSlider.slide6' />
          </p>
          <div className='customButton basicFlex'>
            <Link
              to='/karrier'
              style={{
                textDecoration: 'none',
                color: '#505050',
                fontSize: '16px !important',
                fontStyle: 'italic',
                fontWeight: 500,
              }}
            >
              <Translate value='imgSlider.button' />
            </Link>
          </div>
        </Caption>
      </Slide>
      <Slide image={<img alt='' src='/img/headerPics/contact2.jpg' />}>
        <Caption
          placement='right'
          className='basicFlex firstSlide'
          style={{ alignItems: 'flex-end' }}
        >
          <p className='slideTitle'>
            <Translate value='imgSlider.slide7' />
          </p>
          <div className='customButton basicFlex'>
            <Link
              to='/kapcsolat'
              style={{
                textDecoration: 'none',
                color: '#505050',
                fontSize: '16px !important',
                fontStyle: 'italic',
                fontWeight: 500,
              }}
            >
              <Translate value='imgSlider.button' />
            </Link>
          </div>
        </Caption>
      </Slide>
    </Slider>
  );
};

export default ImgSlider;
