import React from 'react';

const Footer = () => {
  return (
    <footer className='page-footer' style={{ backgroundColor: '#505050' }}>
      <div className='container'>
        <div className='row'>
          <div className='col l4 s12'>
            <p>Eky-Light Kereskedelmi és Szolgáltató Kft.</p>
            <br />
            <p>Székhely:</p>
            <p>H-1045 Budapest, Ódry Árpád utca 3.</p>
            <p>Telephely és levelezési cím:</p>
            <p>H-2120 Dunakeszi, Pallag u.4.</p>
            <p>Adószám: 13249456-2-41</p>
            <p>Cégjegyzékszám: 01-09-725760</p>
          </div>
          <div className='col l4 s12'>
            <p>Forint bankszámlaszám:</p>
            <p>Budapest Bank</p>
            <p>HU94 10101346-44307300-01005001 (HUF)</p>
            <br />
            <p>Deviza számlaszám: </p>
            <p>OTP Banka Slovensko SK84 5200-0000- 0000-1511-9098</p>
            <p>Font számlaszám: </p>
            <p>SK54 5200 0000 0000 1885 2295</p>
            <p>SWIFT number: OTPVSKBX </p>
            <p>TimoCom: 113975 </p>
            <p>GDPR</p>
          </div>
          <div className='col l4 s12'>
            <a href='/img/sajtokozlemeny.pdf' target='blank'>
              <img
                className='responsive-img'
                src='/img/footer_img.svg'
                alt='szechenyi'
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
