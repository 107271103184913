import React from 'react';
import { connect } from 'react-redux';
import { supportedLocales } from '../../config/i18n';
import { setLocaleWithFallback } from '../../actions/i18n';

class LanguageSwitcher extends React.Component {
  handleLanguageLinkClick = (e, code) => {
    e.preventDefault();
    this.props.setLocaleWithFallback(code);
  };
  render() {
    return (
      <div className='flags'>
        {Object.keys(supportedLocales).map((code) => (
          <img
            src={`/img/${code}.svg`}
            className={code}
            key={code}
            onClick={(e) => this.handleLanguageLinkClick(e, code)}
            alt={supportedLocales[code]}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
});

const mapDispatchToProps = {
  setLocaleWithFallback,
};
export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
