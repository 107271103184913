import React, { Fragment } from 'react';
import SmallSlider from '../layout/SmallSlider';
const imgGalery = [
  { url: '/img/newGallery/gallery.jpg' },
  { url: '/img/newGallery/gallery1.jpg' },

  { url: '/img/newGallery/gallery5.jpg' },
  { url: '/img/newGallery/gallery6.jpg' },
  { url: '/img/newGallery/gallery7.jpg' },
  { url: '/img/newGallery/gallery9.jpg' },
  { url: '/img/newGallery/gallery11.jpg' },

  { url: '/img/newGallery/gallery16.jpg' },

  { url: '/img/newGallery/gallery20.jpg' },

  { url: '/img/newGallery/gallery22.jpg' },
  { url: '/img/newGallery/gallery23.jpg' },
  { url: '/img/newGallery/gallery25.jpg' },
  { url: '/img/newGallery/gallery27.jpg' },
  { url: '/img/newGallery/gallery30.jpg' },
  { url: '/img/newGallery/gallery40.jpg' },

  { url: '/img/newGallery/gallery12.jpg' },
  { url: '/img/newGallery/gallery36.jpg' },

  { url: '/img/newGallery/gallery38.jpg' },
  { url: '/img/newGallery/gallery45.jpg' },
  { url: '/img/newGallery/gallery46.jpg' },
  { url: '/img/newGallery/gallery48.jpeg' },
  { url: '/img/newGallery/gallery49.jpg' },
];

const Gallery = () => {
  return (
    <Fragment>
      <SmallSlider img='gal' placement='right' slide='slide5' />
      <div className='container'>
        <div className='imgGalleryContainer'>
          {imgGalery.map((picture) => (
            <img
              className='materialboxed galeryImg'
              alt='galleryPic'
              src={picture.url}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Gallery;
