import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import HomePage from './components/pages/HomePage';
import NavBar from './components/layout/NavBar';
import Footer from './components/layout/Footer';
import TransferPage from './components/pages/TransferPage';
import CarPark from './components/pages/CarPark';
import Duty from './components/pages/Duty';
import OurMission from './components/pages/OurMission';
import StorageDepo from './components/pages/StorageDepo';
import Career from './components/pages/Career';
import Contact from './components/pages/Contact';
import ThankYou from './components/pages/ThankYou';
import Gallery from './components/pages/Gallery';
import { Provider } from 'react-redux';
import store from './store';

function App() {
  useEffect(() => {
    M.AutoInit();
  });

  return (
    <Provider store={store}>
      <Router>
        <NavBar />
        <Switch>
          <Route path='/' exact component={HomePage} />
          <Route path='/jarataink' exact component={TransferPage} />
          <Route path='/gepkocsipark' exact component={CarPark} />
          <Route path='/vamolas' exact component={Duty} />
          <Route path='/kuldetesi-nyilatkozat' exact component={OurMission} />
          <Route path='/raktarozas' exact component={StorageDepo} />
          <Route path='/karrier' exact component={Career} />
          <Route path='/kapcsolat' exact component={Contact} />
          <Route path='/galeria' exact component={Gallery} />
          <Route path='/response' exact component={ThankYou} />
        </Switch>
        <Footer />
      </Router>
    </Provider>
  );
}

export default App;
