const translations = {
  en: {
    navbar: {
      navItem1: 'Customs',
      navItem2: 'About us',
      navItem3: 'Destination Countries',
      navItem4: 'Vehicle fleet',
      navItem5: 'Storage depot',
      navItem6: 'Gallery',
      navItem7: 'Our mission',
      navItem8: 'Career',
      navItem9: 'Contact',
      dropdownMenu1: {
        item1: 'Sweden, Norway, Finland',
        item2: 'Denmark, Benelux',
        item3: 'Hungary and cetnral Europe',
        item4: 'England, Ireland',
      },
      dropdownMenu2: {
        item1: 'Driver',
        item2: 'Logistics',
        item3: 'Office Assistant',
      },
    },
    introduction: {
      title: 'About Us',
      description: {
        part1:
          'Our main business is the organization of complete road and combined transport in North and West Europe',
        part2: 'Company information',
        companyInfoList: {
          listItem1: 'Hungarian private company (100% family owned)',
          listItem2: 'Founded in 2004',
          listItem3: '57 employees',
          listItem4:
            'Own own site in Dunakeszi, with a self-sustaining, intelligent office systems.',
          listItem5: 'More than 6,000 orders annually.',
          listItem6: 'Share capital of the Limited Company: HUF 15 million',
        },
        part3: 'Our services',
        whatWeOfferList: {
          listItem1:
            'Groupage service in domestic and international freight transport',
          listItem2: 'Warehousing',
          listItem3: 'Picking',
          listItem4: 'EXPORT - IMPORT Customs administration at our premises',
        },
        part4: 'The operating principles of our company',
        part5:
          "Our company's motto is „Whether the order is large or small it will receive our utmost attention”",
        mottoList: {
          listItem1: 'Ethical business conduct',
          listItem2: 'Encourage family team spirit',
          listItem3:
            'The primary goal of our company is the satisfaction of our customers and employees',
          listItem4: 'High level of quality and performance of our services',
          listItem5: 'High level of quality and performance of our services',
          listItem6:
            'Decentralized organizational structure and cooperative corporate governance strategy',
        },
      },
    },
    destination: {
      title: 'Our destinations',
      countryList: {
        listItem1: 'Hungary',
        listItem2: 'Norway, Sweden, Finland',
        listItem3: 'Benelux and Denmark',
        listItem4: 'Great Britain, Ireland',
        listItem5:
          'Serbia, Croatia, Slovenia, Austria, Slovakia, Czech Republic',
      },
      description: {
        part1:
          'For us, the primary aim is to deliver the shipments safely and on time.',
        part2:
          'Our transport organizers themselves have visited these routes several times, so they have also gained personal experience. They are available to our customers with good communication skills, excellent problem-solving skills and a high degree of flexibility',
      },
      cardDetails: {
        card1: {
          title: 'Svéd-Finn-Norvég fuvarszervező:',
          contact: {
            name: 'Bori András',
            phone: '+36 20/472-0169',
            email: 'andras.bori@ekytrans.hu',
          },
        },
        card2: {
          title: 'Dán, Benelux államok:',
          contact: {
            name: 'Boldizsár András',
            phone: '+36 20/886-8542',
            email: 'andras.boldizsar@ekytrans.hu',
          },
        },
        card3: {
          title: 'Belföld és szomszédos országok:',
          contact: {
            name: 'Simon Dorka',
            phone: '0620/3610359',
            email: 'dorka.simon@ekytrans.hu',
          },
        },
      },
    },
    fleet: {
      description: {
        part1:
          'Our vehicles are equipped with ADR equipment (suitable for the transport of dangerous goods) and an on-board satellite tracking system.',
        part2:
          'We use innovative, real-time data software developed for our company, which guarantees accurate data recording and fast information flow.',
        part3:
          "Our drivers have decades of driving experience, an international driver's license and an ADR certificate.",
        part4: 'Our vehicles:',
      },
      tableTitles: {
        title1: 'Weight (t)',
        title2: 'Pallet (pcs)',
        title3: 'Box meter (ldm)',
        title4: 'ADR',
        title5: 'Superstructure',
      },
    },
    storage: {
      description: {
        part1:
          'With a covered storage capacity of more than 2,500 m2, it is also possible to store products that require heating.',
        part2: 'The storage halls offer both shelf and block storage.',
        part3:
          'By standardizing logistics processes, our work processes are simplified. Increasing our competitiveness and adding value is provided by our flexible, customer-tailored services supported by an innovative system.',
      },
      listTitles: {
        title1: 'Services',
        title2: 'Safety',
        title3: 'Customized logistics services',
        title4: 'Customized storage',
      },

      list1: {
        listItem1: 'Short and long term storage',
        listItem2: 'Inventory and turnover reporting on demand',
        listItem3: 'Inventory records',
        listItem4: 'Two-shift work schedule (7:00 -21: 00)',
        listItem5: 'Customs administration (link to in-page tab)',
      },
      list2: {
        listItem1: 'Stock and liability insurance',
        listItem2: 'Security service, access control system, reception service',
        listItem3: 'Closed circuit camera system',
        listItem4: '24 hour dispatch service',
        listItem5: '24-hour fire and burglar alarm system',
      },
      list3: {
        listItem1: 'Reviewing, planning and optimizing logistics processes',
        listItem2: 'Distribution preparation',
        listItem3: 'Labeling, marking',
        listItem4: 'Unit load training',
        listItem5: 'Return goods handling',
        listItem6: 'Quality control',
      },
      list4: {
        listItem1: 'Complex warehouse logistics service',
        listItem2: 'Transhipment',
        listItem3: 'Picking',
        listItem4: 'Selection, management of individual processes',
        listItem5: 'Rental of containers',
        listItem6: 'Parking of cars and trucks',
      },
    },
    imgSlider: {
      slide1: 'Welcome to our page!',
      slide2: 'Destination Countries',
      slide3: 'Vehicle Fleet',
      slide4: 'Storage depot',
      slide5: 'Gallery',
      slide6: 'Career',
      slide7: 'Contact',
      slide8: ' ',
      button: 'Open',
    },
    form: {
      name: 'Name',
      email: 'E-mail address',
      phone: 'Phone',
      text: 'Message',
      uploadDoc: 'Upload document',
    },
    career: {
      title: 'Karrier',
      current: {
        title: 'Bővülő csapatunkba munkatársat keresünk ',
        position: 'Nemzetközi gépkocsivezető ',
        part1: 'pozícióba',
      },

      description: {
        part1:
          'A 2004-ben alakult Eky-Light Kft. egy dinamikusan fejlődő belföldi- és nemzetközi áruszállítással, logisztikával és raktározással fogalakozó vállalkozás. Biztos háttérrel, stabil megbízói körrel rendelkezünk, melynek célpiaca Magyarország valamint Nyugat- és Észak-Európa országai.',
        part2:
          'A gazdasági változásokhoz könnyedén alkalmazkodunk, ezért is tudunk folyamatosan fejlődni a piaci versenyben. ',
        part3:
          'Flottabővítés miatt, hosszú távú együttműködésre keresünk nemzetközi gépkocsivezető kollégát, aki csapatunkat erősítené.',
      },
      req: {
        title: 'Elvárások:',
        part1: 'Érvényes C+E-kategóriás jogosítvány – feltétel',
        part2: 'GKI,  PÁV II, tachograf kártya - feltétel',
        part3: 'ADR előnyt jelent',
        part4: 'Nemzetközi fuvarozási tapasztalat előnyt jelent',
        part5: 'Pontosság',
        part6: 'Megbízhatóság',
        part7: 'Referencia-személy megjelölése',
      },
      offer: {
        title: 'Amit kínálunk',
        part1: 'Fiatal, jól felszerelt gépjárműpark',
        part2: 'Teljesítményorientált bérezés',
        part3: 'Pontos fizetés, prémium kifizetés',
        part4: 'Rövid nemzetközi utak (max. 10 nap)',
        part5:
          'Kiszámíthatóság, határozatlan idejű munkaszerződés, megbízható csapat',
      },

      exp: {
        title: 'Szükséges tapasztalat:',
        part1: 'min. 1 év szakmai tapasztalat',
      },
      lang: {
        title: 'Nyelvtudás',
        part1: 'Nem szükséges, de előnyt jelent',
      },
      others: {
        title: 'Egyéb juttatások:',
        part1: 'mobiltelefon',
        part2: 'munkaruha',
      },
    },
    duty: {
      title: 'Customs administration',
      descriptionTitle: {
        title1: 'Customs administration at our premises',
        title2: 'Import',
        title3: 'Direct Customs Clearance Order',
        title4: 'Indirect Customs Representation',
        title5: 'Export',
      },
      links: {
        link1: 'https://vamtanacsado.hu/ajanlatkeres/',
        link2:
          'https://vamtanacsado.hu/wp-content/uploads/Eseti-kozvetlen-megbizas-20200421.pdf',
      },
      description: {
        part0: 'Through our customs agency ',
        part1:
          ' we provide a full range of export and import customs agency services and foreign technical consulting.',
        part2:
          'In the case of shipments and transport orders from a third country, ie from outside the European Union, you can request release for free circulation (domesticization) at our Dunakeszi site for your import customs goods, which saves you time and costs.',
        part3: 'You can download the Direct Customs Management Order here: ',
        part4:
          'Send the completed Order together with the shipment documents to the custom',
        part5:
          'During the inspection of the documents, you will have the opportunity to correct the documents in accordance with the needs of NAV, obtain the necessary tariff headings and learn the exact customs calculation for the consignment already during the transport period.',
        part6:
          'In addition to the normal customs administration, you also have the option of using an Indirect Customs Representation when, under certain legal conditions, you do NOT have to pay the 27% cost of import VAT.',
        part7: 'For details ask for our offer: ',
        part8:
          'In case of deliveries in third countries, we provide export customs clearance.',
        part9:
          'You can download the Occasional Customs Management Order here: ',
        part10:
          'Forward the completed Order together with the documents of the shipment to the email address custom@vamtanacsado.hu.',
        part11:
          'Please indicate the need for proof of origin above 6,000 EUR in advance.',
        part12:
          'Special individual needs, such as licensing, customs and foreign trade technical consulting, management of a private customs warehouse, completion of other customs formalities, or full handling of goods on site.',
        part13: 'For the Offer for your individual needs: ',
      },
    },
  },

  hu: {
    navbar: {
      navItem1: 'Vám',
      navItem2: 'Rólunk',
      navItem3: 'Járataink',
      navItem4: 'Gépkocsipark',
      navItem5: 'Raktározás',
      navItem6: 'Galéria',
      navItem7: 'Küldetési nyilatkozat',
      navItem8: 'Karrier',
      navItem9: 'Kapcsolat',
      dropdownMenu1: {
        item1: 'Svéd, Finn, Norvég területek',
        item2: 'Dán, Benelux államok',
        item3: 'Belföld és szomszédos országok',
        item4: 'Angol, Ír területek',
      },
      dropdownMenu2: {
        item1: 'Sofőr',
        item2: 'Logisztikus',
        item3: 'Irodai asszisztens',
      },
    },
    introduction: {
      title: 'Rólunk',
      description: {
        part1:
          'Fő üzleti tevékenységünk a komplett közúti és kombinált szállítások szervezése Észak- és Nyugat-Európában',
        part2: 'Céginformáció',
        companyInfoList: {
          listItem1: 'Magyar magánvállalat (100 % családi tulajdonú)',
          listItem2: '2004-ben alapítva',
          listItem3: '57 alkalmazott',
          listItem4:
            'Saját telephely Dunakeszin, önfenntartó, intelligens rendszerű irodaházzal',
          listItem5: 'Évente több mint 6000 megrendelés',
          listItem6: 'A Kft alaptőkéje: 15 millió Ft',
        },
        part3: 'Szolgáltatásaink',
        whatWeOfferList: {
          listItem1:
            'Gyűjtő-fuvarozó szolgáltatás a hazai és nemzetközi árufuvarozásban',
          listItem2: 'Raktározás',
          listItem3: 'Komissiózás',
          listItem4: 'EXPORT - IMPORT Vámügyintézés a telephelyünkön',
        },
        part4: 'Cégünk működési alapelvei ',
        part5: 'Cégünk mottója: „A legkisebbet is a legnagyobb figyelemmel.”',
        mottoList: {
          listItem1: 'Etikus üzleti magatartás',
          listItem2: 'Családias csapatszellem ösztönzése',
          listItem3:
            'Cégünk elsődleges célja ügyfeleink és munkatársaink elégedettsége',
          listItem4: 'Szolgáltatásaink magas szintű minősége és teljesítése',
          listItem5:
            'Innovatív alkalmazkodás a gazdasági és technikai változásokhoz és igényekhez',
          listItem6:
            'Decentralizált szervezeti felépítés és kooperatív vállalatvezetési stratégia',
        },
      },
    },
    destination: {
      title: 'Járataink',
      countryList: {
        listItem1: 'Magyarország',
        listItem2: 'Norvégia, Svédország, Finnország',
        listItem3: 'Benelux államok és Dánia',
        listItem4: 'Nagy-Britannia, Írország',
        listItem5:
          'Szerbia, Horvátország, Szlovénia, Ausztria, Szlovákia, Cseh Köztársaság',
      },
      description: {
        part1:
          'Számunkra elsődleges a küldemények épségben és időben történő leszállítása.',
        part2:
          'Fuvarszervezőink maguk is többször jártak ezeken az útvonalakon, így személyes tapasztalatokra is szert tettek. Jó kommunikációs készséggel, kiváló problémamegoldó képességgel és nagyfokú rugalmassággal állnak ügyfeleink rendelkezésére.',
      },
      cardDetails: {
        card1: {
          title: 'Svéd-Finn-Norvég fuvarszervező:',
          contact: {
            name: 'Bori András',
            phone: '+36 20/472-0169',
            email: 'andras.bori@ekytrans.hu',
          },
        },
        card2: {
          title: 'Dán, Benelux államok:',
          contact: {
            name: 'Boldizsár András',
            phone: '+36 20/886-8542',
            email: 'andras.boldizsar@ekytrans.hu',
          },
        },
        card3: {
          title: 'Belföld és szomszédos országok:',
          contact: {
            name: 'Simon Dorka',
            phone: '0620/3610359',
            email: 'dorka.simon@ekytrans.hu',
          },
        },
      },
    },
    fleet: {
      description: {
        part1:
          'Járműveink rendelkeznek ADR felszereléssel (veszélyes áruk szállítására való alkalmasság) és fedélzeti műholdas nyomkövető rendszerrel vannak felszerelve.',
        part2:
          'Cégünk számára kifejlesztett innovatív, valós idejű adatszolgáltatású szoftvert használunk, amely garantálja az adatok pontos nyilvántartását és a gyors információáramlást.',
        part3:
          'Gépkocsivezetőink több évtizedes vezetői tapasztalattal, nemzetközi vezetői engedéllyel és ADR igazolvánnyal rendelkeznek.',
        part4: 'Járműveink:',
      },
      tableTitles: {
        title1: 'Teherbírás (t)',
        title2: 'Raklap (db)',
        title3: 'Ládaméret(ldm)',
        title4: 'ADR',
        title5: 'Felépítmény',
      },
    },
    storage: {
      description: {
        part1:
          'A 2500 m2-t meghaladó fedett raktárkapacitás, lehetőség van fűtést igénylő termékek tárolására is. A raktárcsarnokokban polcos és tömbös tárolásra egyaránt lehetőség nyílik. A logisztikai folyamatok standardizálásával a munkafolyamataink egyszerűsödnek. Versenyképességünk növelését az a hozzáadott érték jelenti, amit rugalmas, ügyfélre szabott, innovatív rendszerrel támogatott szolgáltatásaink biztosítanak.',
        part2:
          'A nálunk raktározott áruk tárolása tágas alapterületen, megfelelő teherbírású polcrendszeren történik.',
        part3:
          'A precíz, pontos és gyors munkavégzés lehetővé teszi a dinamikus árumozgatást, ennek köszönhetően az áruk igény szerinti ki- és beraktározását, valamint komissiózását is vállalni tudjuk.',
      },
      listTitles: {
        title1: 'Szolgáltatások',
        title2: 'Biztonság',
        title3: 'Testre szabott logisztikai szolgáltatások',
        title4: 'Ügyfélre szabott raktározás',
      },

      list1: {
        listItem1: 'Rövid és hosszú távú tárolás',
        listItem2: 'Igény szerint készlet- és forgalmi jelentésküldés',
        listItem3: 'Tárhely szintű nyilvántartás',
        listItem4: 'Kétműszakos munkarend (7:00 -21:00)',
        listItem5: 'Vámügyintézés (link az oldalon belüli fülre)',
      },
      list2: {
        listItem1: 'Készlet- és felelősségbiztosítás',
        listItem2: 'Őrszolgálat, beléptető rendszer, portaszolgálat',
        listItem3: 'Zárt láncú kamerarendszer',
        listItem4: '24 órás diszpécser szolgálat',
        listItem5: '24 órás tűz- és betörésriasztó jelzőrendszer',
      },
      list3: {
        listItem1:
          'Logisztikai folyamatok átvizsgálása, tervezése, optimalizálása',
        listItem2: 'Elosztás-előkészítés',
        listItem3: 'Címkézés, jelölés',
        listItem4: 'Egységrakomány-képzés',
        listItem5: 'Visszáru kezelés',
        listItem6: 'Minőségellenőrzés',
      },
      list4: {
        listItem1: 'Komplex raktár-logisztikai szolgáltatás',
        listItem2: 'Átrakás',
        listItem3: 'Komissiózás',
        listItem4: 'Válogatás, egyedi folyamatok menedzselése',
        listItem5: 'Konténerek bérlése',
        listItem6: 'Személy- illetve tehergépjárművek parkoltatása',
      },
    },
    duty: {
      title: 'Vámügyintézés',
      descriptionTitle: {
        title1: 'Vámügyintézés telephelyünkön',
        title2: 'Import',
        title3: 'Közvetlen Vámkezelési Megbízás',
        title4: 'Közvetett Vámjogi Képviselet',
        title5: 'Export',
      },
      linkTitle: {
        title1: 'itt töltheti le',
      },
      description: {
        part0: 'Vámügynökségünkön keresztül ',
        part1:
          ' teljes körű export és import vámügynökségi szolgáltatást és külkertechnikai tanácsadást biztosítunk.',
        part2:
          'Harmadik országbeli, azaz az Európai Unión kívülről érkező szállítmányok, fuvarmegbízások esetén, már Dunakeszi telephelyünkön szabadforgalomba helyezést (belföldiesítést) kérhet import vámárujára, mellyel időt és költséget takaríthat meg.',
        part3: 'A Közvetlen Vámkezelési Megbízást itt töltheti le: ',
        part4:
          'A kitöltött Megbízást a szállítmány okmányaival együtt továbbítsa a custom@vamtanacsado.hu email címre.',
        part5:
          'Az okmányok ellenőrzése során már a szállítás időtartama alatt lehetősége lesz a NAV igényeinek megfelelően  az okmányok esetleges korrekciójára, a szükséges vámtarifaszámok megszerzésére és a szállítmányra vonatkozó pontos vámkalkuláció megismerésére.',
        part6:
          'A normál vámügyintézésen kívül lehetősége van Közvetett Vámjogi Képviseletet is igénybe venni, amikor bizonyos jogszabályi feltételek megléte mellett NEM kell megfizetnie az import ÁFA 27%-os költségét.',
        part7: 'Részletekért kérje Ajánlatunkat: ',
        part8:
          'Harmadik országbeli kiszállítások esetén, export vámkezelést biztosítunk.',
        part9: 'Az Eseti Vámkezelési Megbízást itt töltheti le: ',
        part10:
          'A kitöltött Megbízást a szállítmány okmányaival együtt továbbítsa a custom@vamtanacsado.hu email címre.',
        part11:
          'Származási igazolás igényét 6.000Eur áruérték felett kérjük, jelezze előre.',
        part12:
          'Különleges egyedi igények, például engedélyeztetés, vám- és külkertechnikai  tanácsadás, magánvámraktár kezelése, egyéb vám formalitások végrehajtása, vagy  a teljes árukezelés a helyszínen megoldható.',
        part13: 'Egyedi igényeire szóló Ajánlatért: ',
      },
    },

    career: {
      title: 'Karrier',
      current: {
        title: 'Bővülő csapatunkba munkatársat keresünk ',
        position: 'Nemzetközi gépkocsivezető ',
        part1: 'pozícióba',
      },

      description: {
        part1:
          'A 2004-ben alakult Eky-Light Kft. egy dinamikusan fejlődő belföldi- és nemzetközi áruszállítással, logisztikával és raktározással foglalkozó vállalkozás. Biztos háttérrel, stabil megbízói körrel rendelkezünk, melynek célpiaca Magyarország valamint Nyugat- és Észak-Európa országai.',
        part2:
          'A gazdasági változásokhoz könnyedén alkalmazkodunk, ezért is tudunk folyamatosan fejlődni a piaci versenyben. ',
        part3:
          'Flottabővítés miatt, hosszú távú együttműködésre keresünk nemzetközi gépkocsivezető kollégát, aki csapatunkat erősítené.',
      },
      req: {
        title: 'Elvárások:',
        part1: 'Érvényes C+E-kategóriás jogosítvány – feltétel',
        part2: 'GKI,  PÁV II, tachograf kártya - feltétel',
        part3: 'ADR előnyt jelent',
        part4: 'Nemzetközi fuvarozási tapasztalat előnyt jelent',
        part5: 'Pontosság',
        part6: 'Megbízhatóság',
        part7: 'Referencia-személy megjelölése',
      },
      offer: {
        title: 'Amit kínálunk',
        part1: 'Fiatal, jól felszerelt gépjárműpark',
        part2: 'Teljesítményorientált bérezés',
        part3: 'Pontos fizetés, prémium kifizetés',
        part4: 'Rövid nemzetközi utak (max. 10 nap)',
        part5:
          'Kiszámíthatóság, határozatlan idejű munkaszerződés, megbízható csapat',
      },

      exp: {
        title: 'Szükséges tapasztalat:',
        part1: 'min. 1 év szakmai tapasztalat',
      },
      lang: {
        title: 'Nyelvtudás',
        part1: 'Nem szükséges, de előnyt jelent',
      },
      others: {
        title: 'Egyéb juttatások:',
        part1: 'mobiltelefon',
        part2: 'munkaruha',
      },
    },

    imgSlider: {
      slide1: 'Üdvözöljük Önt honlapunkon!',
      slide2: 'Járataink',
      slide3: 'Gépkocsipark',
      slide4: ' ',
      slide5: 'galéria',
      slide6: 'Karrier',
      slide7: 'Kapcsolat',
      slide8: 'Vám',
      button: 'Megnézem',
    },
    form: {
      name: 'Név',
      email: 'E-mail cím',
      phone: 'Telefon',
      text: 'Üzenet',
      uploadDoc: 'Dokumentum csatolása',
    },
  },
};
export default translations;
