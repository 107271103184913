import React from 'react';
import { Slide, Slider, Caption } from 'react-materialize';
import { Translate } from 'react-redux-i18n';

const SmallSlider = (props) => {
  const { img, placement, slide } = props;
  return (
    <Slider
      className='smallSlider'
      fullscreen={false}
      options={{
        duration: 500,
        height: 400,
        indicators: false,
        interval: 6000,
      }}>
      <Slide image={<img alt='headerImg' src={`/img/headerPics/${img}.jpg`} />}>
        <Caption placement={placement}>
          <p className='slideTitle'>
            <Translate value={`imgSlider.${slide}`}></Translate>
          </p>
        </Caption>
      </Slide>
    </Slider>
  );
};

export default SmallSlider;
