import React, { Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import SmallSlider from '../layout/SmallSlider';

const OurMission = () => {
  return (
    <Fragment>
      <SmallSlider img='duty' placement='left' slide='slide8' />
      <div className='container'>
        <p className='description'>
          <Translate value='mission.description.part1' /> <br />
          <Translate value='mission.description.part2' /> <br />
          <Translate value='mission.description.part3' /> <br />
          <Translate value='mission.description.part4' /> <br />
          <Translate value='mission.description.part5' /> <br />
          <Translate value='mission.description.part6' /> <br />
          <Translate value='mission.description.part7' /> <br />
          <Translate value='mission.description.part8' /> <br />
          <Translate value='mission.description.part9' /> <br />
          <Translate value='mission.description.part10' /> <br />
          <Translate value='mission.description.part11' /> <br />
          <Translate value='mission.description.part12' /> <br />
          <Translate value='mission.description.part13' /> <br />
          <Translate value='mission.description.part14' /> <br />
          <Translate value='mission.description.part15' />
        </p>
      </div>
    </Fragment>
  );
};

export default OurMission;
